<script setup lang="ts">
import { ref } from 'vue';
import {
	BaseButton,
	FieldCheckbox,
	FieldPhoneNumber,
} from '@magma-app/magma-lapilli';
import { useRoute } from 'vue-router';
import { useHelpeeData } from '@/api/complete-helpee-signup/use-helpee-data';
import { hexToRgb } from '@/utils/color';

interface Props {
	data: any;
}

defineProps<Props>();

defineEmits(['submit']);

const phone = ref<string>('');
const accept = ref<boolean>(false);
const organizationName = 'Learnico';
</script>

<template>
	<div class="flex-1 flex flex-col">
		<div class="h-[244px] w-full overflow-hidden relative">
			<div
				class="w-full aspect-square bg-[url('/img/illustration_helper_onboarding_background.svg')] bg-cover bg-no-repeat bg-center relative flex items-center justify-center flex-col gap-6 h-[244px]"
			>
				<div
					class="msg1 md:w-96 w-80 p-4 bg-white border border-border-subtle rounded-2xl shadow-lg flex gap-4 items-center"
				>
					<img
						v-if="data?.signUpInformation?.helpersHighlighted"
						:src="
							data?.signUpInformation?.helpersHighlighted[0]?.profilePictureUrl
						"
						class="shrink-0 h-auto w-10 rounded-full"
					/>
					<div class="flex-1">
						<div class="w-full flex justify-between text-mgm-txt-xs mb-0.5">
							<p
								v-if="data?.signUpInformation?.helpersHighlighted"
								class="font-semibold text-foreground-emphasis"
							>
								{{ data?.signUpInformation?.helpersHighlighted[0]?.firstname }}
							</p>
							<span class="text-foreground-subtle">9:41</span>
						</div>
						<p class="text-mgm-txt-xs text-foreground-default">
							{{ $t('helperOnboarding.share.message1.start') }}
							{{ organizationName
							}}{{ $t('helperOnboarding.share.message1.end') }}
						</p>
					</div>
				</div>
				<div
					class="msg2 md:w-96 w-80 p-4 bg-white border border-border-subtle rounded-2xl shadow-lg flex gap-4 items-center"
				>
					<img
						v-if="data?.signUpInformation?.helpersHighlighted"
						:src="
							data?.signUpInformation?.helpersHighlighted[1]?.profilePictureUrl
						"
						class="shrink-0 h-auto w-10 rounded-full"
					/>
					<div class="flex-1">
						<div class="w-full flex justify-between text-mgm-txt-xs mb-0.5">
							<p
								v-if="data?.signUpInformation?.helpersHighlighted"
								class="font-semibold text-foreground-emphasis"
							>
								{{ data?.signUpInformation?.helpersHighlighted[1]?.firstname }}
							</p>
							<span class="text-foreground-subtle">8:25</span>
						</div>
						<p class="text-mgm-txt-xs text-foreground-default">
							{{ $t('helperOnboarding.share.message2.start') }}
							{{ organizationName
							}}{{ $t('helperOnboarding.share.message2.end') }}
						</p>
					</div>
				</div>
			</div>
			<div
				class="w-full bg-gradient-to-b from-transparent to-white h-[130px] absolute left-0 bottom-0"
			></div>
		</div>
		<div class="px-10 py-4 flex justify-between flex-col flex-1">
			<div>
				<h1 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
					{{ $t('importProspects.phone.title.start') }}
					{{ data?.signUpInformation?.firstname
					}}{{ $t('importProspects.phone.title.end') }}
				</h1>
				<p class="text-mgm-txt-sm text-foreground-default mb-5">
					{{ $t('importProspects.phone.subtitle') }}
				</p>
				<FieldPhoneNumber
					v-model="phone"
					required
					:label="$t('importProspects.phone.label')"
					:description="$t('importProspects.phone.description')"
				/>

				<div class="flex items-center gap-3 my-5">
					<FieldCheckbox
						id="accept"
						v-model="accept"
						:custom-color="data?.signUpInformation?.primaryColor"
						:custom-rgb-color="
							hexToRgb(data?.signUpInformation?.primaryColor || '')
						"
					/>
					<p class="text-mgm-txt-sm text-foreground-default">
						{{ $t('importProspects.phone.terms.start') }}
						<a
							href="/"
							:style="{ color: data?.signUpInformation?.primaryColor }"
							>{{ $t('importProspects.phone.terms.terms') }}</a
						>
						{{ $t('importProspects.phone.terms.and') }}
						<a
							href="/"
							:style="{ color: data?.signUpInformation?.primaryColor }"
							>{{ $t('importProspects.phone.terms.privacy') }}</a
						>
					</p>
				</div>
			</div>

			<BaseButton
				@click="$emit('submit', { phone, accept })"
				size="lg"
				class="w-full mb-8 mt-3"
				:custom-color="data?.signUpInformation?.primaryColor"
				:custom-rgb-color="
					hexToRgb(data?.signUpInformation?.primaryColor || '')
				"
				:disabled="!phone || !accept || phone === ''"
			>
				{{ $t('importProspects.phone.cta') }}</BaseButton
			>
		</div>
	</div>
</template>
